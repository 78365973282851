import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import { PageContent } from '../components/PageContent'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Title = styled.h1 `
  font-size: 2rem;
`



const NotFoundPage = () => {
  return (
    <>
      <SmallBanner/>
      <Container>
        <PageContent>
          <Title>404- Stránka nenalezena</Title>
          <p>Zkontrolujte prosím správnost adresy</p>
        </PageContent>
      </Container>
    </>
  )
}

export default NotFoundPage

export const Head = () => (
  <>
    <title>Cestu-j</title>
    <meta name="description" content="Cestovní itineráře na míru" />
  </>
)